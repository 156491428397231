<template>
  <section v-if="data && data.image" class="banner"
    v-bind:style="{ backgroundImage:`url('${data.image}')`}">
    <div class="container" v-if="data">
      <div class="text_banner">
        <div class="title_sub">
          <span style="text-shadow: rgb(0 0 0) 2px 2px 13px;">{{ data?.category_name }}</span>
        </div>
        <h1 v-html="data.title" style="text-shadow: rgb(0 0 0) 2px 2px 13px; white-space: pre-wrap;"></h1>
        <p class="des_sub titlea" v-html="data.sub_title" style="text-shadow: rgb(0 0 0) 2px 2px 13px;"></p>
        <SeeMore :link="`${data?.id ? `/detail/${data?.id }` : null}`" :language="language"/>
      </div>
    </div>
  </section>
</template>
<script>
import SeeMore from "../button/seeMore.vue";
// import mapGetters from "vuex";

export default {
  components: {
    SeeMore,
  },
  props: {
    data: {},
    language: null
  },
  data() {
    return {};
  },
  // computed: {
  //   ...mapGetters(["locale"]),
  // },
  methods: {},
};
</script>
