<template>
  <BannerNews :data="data" :language="language"/>
  <Tabs :data="dataTabs" @ageWasUpdated="ageWasUpdated" :index="index" :language="language"/>
  <section class="article-list bg-gray">
    <div class="container bg-line">
      <div class="article-list__grid" v-if="newsList?.length > 0">
        <div class="article" v-for="(item, index) in newsList" :key="index">
          <router-link :to="`/detail/${item.id}`">
            <div class="article__image">
              <img :src="item.image" alt="article-1" />
            </div>
            <span class="article__category">{{item[locale]?.category_name}}</span>
            <h2 class="article__title">
              {{item[locale]?.title}}
            </h2>
            <SeeMore :link="`/detail/${item.id}`" :language="language"/>
          </router-link>
        </div>
      </div>
      <button class="article-btn" @click="seeMoreNew()" v-if="language && Object.keys(language).length > 0"><span>{{language["home::seemore"][locale]}}</span></button>
    </div>
  </section>
  <Contact :language="language"/>
</template>

<script>
/* global $ */
// import { mapMutations } from "vuex";
import SeeMore from "@/components/button/seeMore.vue";
import BannerNews from "@/components/common/BannerNews.vue";
import Tabs from "@/components/common/Tabs.vue";
import Contact from "@/components/home/Contact.vue";
import NewsService from '@/services/NewsService';
import { mapGetters } from "vuex";
import CommonSerive from "@/services/CommonSerive";

export default {
  components: {
    BannerNews,
    Tabs,
    Contact,
    SeeMore,
  },
  created(){
    this.dataHome();
  },
  data() {
    return {
      language: [],
      dataTabs: [
        {
          name: "other::allnew",
          id: "overview",
        },
        {
          name: "menu::news",
          id: "overview",
        },
        {
          name: "home::recruitment",
          id: "overview",
        },
      ],
      data: {
        category: "menu::news",
        title:
          "Sáng kiến khu công nghiệp sinh thái cho các khu công nghiệp bền vững",
        des: "Amber Tay Bac - Ho Xa công bố báo cáo kết quả hoạt động năm 2021. Dưới đây là những điểm nổi bật.",
        image: "",
        image_banner: "",
      },
      dataPopUp: "",
      dataSlide: "",
      itemMain: [],
      itemSub: [],
      newsList: [],
      index: "",
      id: 0,
      page: 1,
      perPage: 9
    };
  },
  watch: {
    '$route.query'() {
      console.log(222)
    },
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.getListNews(0, 9);
  },
  computed: {
    ...mapGetters(["locale", "info"]),
  },
  methods: {
    ageWasUpdated(n){
      this.getListNews(n, 9)
      this.id = n;
    },
    activePopup() {
      $("#popupcommonpromos").removeClass("hide");
      $("#popupcommonpromos").addClass("active");
    },
    setMenuActive(index, sub) {
      this.$store.commit("setMenuActive", index);
      this.$store.commit("setSubActive", sub);
    },
    async getListNews(id ,perPage) {
      let params = 'perPage=' + perPage +'&page=1&id=' + id;
      await NewsService.getNewsList(params)
        .then((resp) => {
          this.newsList = resp.data.data;
          let dataFind = {}
          dataFind = resp.data.data.find(item => item.is_top_news === 1 || item.is_feature === 1);
          if (dataFind) {
            this.data.title = dataFind[this.locale]?.title;
            this.data.sub_title = dataFind[this.locale]?.sub_title;
            this.data.category_name = dataFind[this.locale]?.category_name;
            this.data.image = dataFind?.image_banner !== '' && dataFind?.image_banner?.replaceAll(" ", "%20") ? dataFind?.image_banner?.replaceAll(" ", "%20") : dataFind?.image?.replaceAll(" ", "%20");
            this.data.id = dataFind?.id;
          }
        })
        .catch(() => { });
    },
    seeMoreNew(){
      let a = this.perPage + 9;
      this.getListNews(this.id, a);
    },
    async dataHome() {
      await CommonSerive.getLang()
        .then((resp) => {
          this.language = resp.data.data
        })
        .catch(() => {});
    },
  },
};
</script>
